export function loadClientConstants(): ClientConstants {
    const clientConstantsElement = document.getElementById("ynab_client_constants");
    if (!clientConstantsElement) {
        throw new Error("Missing YNAB_CLIENT_CONSTANTS!");
    }

    const jsonContent = window.decodeURIComponent(
        clientConstantsElement.textContent || clientConstantsElement.innerHTML,
    );

    return JSON.parse(jsonContent);
}

export function buildAmplitudeConfig(constants: ClientConstants) {
    return {
        apiKey: constants.AMPLITUDE_API_KEY,
        appVersion: constants.YNAB_APP_VERSION,
        userId: constants.USER?.id,
    };
}

export function buildRollbarSettings(constants: ClientConstants) {
    const rollbarSettings: any = {
        accessToken: constants.ROLLBAR_CLIENT_TOKEN,
        enabled: constants.ROLLBAR_ENABLED, // Comment this out if you are testing Rollbar locally
        verbose: !constants.PRODUCTION, // We are only verbose when compiled in debug mode
        ignoredMessages: [
            "Just a random error", // This is used in unit tests, so we ignore it
            "Script error.", // We see this error fairly frequently, but it doesn't appear to be our fault,
            // and it doesn't give us any information, so we don't report it.
        ],
        // These are additional fields that you don't want going to rollbar
        scrubFields: ["creditCard"],
        payload: {
            client: {
                javascript: {
                    code_version: constants.YNAB_APP_VERSION,
                    source_map_enabled: true,
                    guess_uncaught_frames: true,
                },
            },
            environment: constants.ROLLBAR_ENVIRONMENT,
        },
    };

    if (constants.USER) {
        rollbarSettings.payload.person = {
            id: constants.USER.id,
            email: constants.USER.email,
        };
    }

    return rollbarSettings;
}
