import * as jQuery from "jquery";

(function ($) {
    let isInitialized = false;
    const SUPPORT_ACCESS_ROUTE = "/support_access";
    const VERIFICATION_ROUTE = "/help/verifications";
    const SIGN_IN_ROUTE = "/sign_in";
    const SIGN_UP_ROUTE = "/sign_up";

    window.SelfService = {
        openChat: function () {
            if (window.Kustomer) {
                window.Kustomer.open();
            }
        },
    };

    const loadChat = function () {
        if (!window.Kustomer && !isInitialized) {
            buildChatContainer();
            window.addEventListener(
                "kustomerLoaded",
                function () {
                    initializeChat();
                },
                { passive: true },
            );
        }
    };

    const describeConversation = function (token) {
        window.Kustomer.addListener("onConversationCreate", function (res) {
            window.Kustomer.describeConversation(
                {
                    conversationId: res.conversationId,
                    customAttributes: {
                        loggedInAsStr: window.YNAB_CLIENT_CONSTANTS.USER.email,
                        loggedInAsTokenStr: token,
                        platformStr: "App - Web",
                    },
                },
                function (success, error) {
                    if (error) {
                        throw new Error(`Unable to describe Kustomer Conversation: ${res.id}`);
                    }
                },
            );
        });
    };

    const initializeChat = function () {
        if (isInitialized) {
            return;
        }
        isInitialized = true;
        initializeKustomer();
    };

    const login = async function (userJwt: string) {
        return await new Promise(function (res, rej) {
            describeConversation(userJwt);
            window.Kustomer.isLoggedIn({ email: window.YNAB_CLIENT_CONSTANTS.USER.email }, function (loggedIn, _) {
                if (!loggedIn) {
                    window.Kustomer.login({ jwtToken: userJwt }, function (success) {
                        if (success) {
                            res(success);
                        } else {
                            rej(new Error("Unable to log in user"));
                        }
                    });
                } else {
                    res(true);
                }
            });
        });
    };

    const buildChatButton = function () {
        const container = document.createElement("div");
        container.classList.add("self-service");

        const btn = document.createElement("button");
        btn.classList.add("ynab-button", "secondary");
        btn.innerHTML =
            '<svg id="icon_sprite_question_mark" viewBox="0 0 14 24" height="24" width="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.9689 15.4286C8.9689 15.902 8.57716 16.2857 8.09393 16.2857H6.12523C5.642 16.2857 5.25026 15.902 5.25026 15.4286C5.25026 9.73328 10.172 10.27 10.172 6C10.172 4.5798 8.52704 3.42857 6.83624 3.42857C5.14544 3.42857 3.47301 4.5798 3.47301 6C3.46709 6.48781 3.54397 6.97319 3.7005 7.43631C3.85944 7.88111 3.62021 8.3679 3.16616 8.5236C3.07356 8.55536 2.97614 8.57156 2.87803 8.57152H1.10078C0.706142 8.57253 0.359217 8.31568 0.252493 7.94349C0.0781101 7.31 -0.00670757 6.65608 0.000413846 6C0.000413846 2.68629 3.13413 0 7.00021 0C10.8663 0 14 2.6862 14 6C13.9999 11.5002 8.9689 11.2858 8.9689 15.4286ZM7.00021 18.8571C8.44991 18.8571 9.62513 20.0084 9.62513 21.4286C9.62513 22.8487 8.44991 24 7.00021 24C5.55051 24 4.37529 22.8487 4.37529 21.4286C4.37529 20.0084 5.55054 18.8572 7.00021 18.8571Z"/></svg>';
        btn.ariaLabel = "?";

        const badge = document.createElement("div");
        badge.classList.add("js-badge", "badge");
        btn.appendChild(badge);

        btn.addEventListener(
            "click",
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            async function () {
                const { token: userJwt } = await getJwt();
                await login(userJwt);
                window.Kustomer.open();
            },
            { passive: true },
        );
        window.Kustomer.addListener("onUnread", function (res) {
            const hasUnread = (res.total || 0) > 0;
            updateBadgeClasses("unread", hasUnread);

            if (hasUnread && !currentRouteDisallowed()) {
                openKustomerConversationWithRetry(res.change.conversationId);
            }
        });
        window.Kustomer.addListener("onClose", function () {
            window.Kustomer.getOpenConversations(function (res) {
                updateBadgeClasses("open", (res.length || 0) > 0);
            });
        });
        window.Kustomer.addListener("onLogin", (res) => {
            openFirstUnreadConversation();
        });

        container.appendChild(btn);
        window.document.body.appendChild(container);

        if (currentRouteDisallowed()) {
            window.Kustomer.close();
        }

        window.Kustomer.getOpenConversations(function (res) {
            const hasOpenConversations = (res.length || 0) > 0;
            updateBadgeClasses("open", hasOpenConversations);
            openFirstUnreadConversation();
        });

        // Go ahead and log in the user into Kustomer chat so they can receive incoming chats
        void login(window.YNAB_CLIENT_CONSTANTS.USER_HELP_ACCESS_INITIAL_JWT);
    };

    function openFirstUnreadConversation() {
        if (currentRouteDisallowed()) {
            return;
        }

        const unreadCounts = window.Kustomer.getUnreadCount();
        if (unreadCounts.total > 0) {
            // we can't get the "most recent unread", so just show the first with any unread messages
            const firstUnreadConversationId = Object.keys(unreadCounts.conversations)
                .filter((conversationId) => unreadCounts.conversations[conversationId] > 0)
                .shift();
            openKustomerConversationWithRetry(firstUnreadConversationId);
        }
    }

    function currentRouteDisallowed(): boolean {
        const currentUrl = window.location.pathname;
        const disallowedRoute = disallowedRoutes().find((route) => currentUrl.startsWith(route));
        return !!disallowedRoute;
    }

    function disallowedRoutes(): string[] {
        // We have pages where we want to override our standard Kustomer behavior when navigating to them.
        // We also have pages where we do not want the chat to automatically open when there are unread messages.
        // Note that `SIGN_IN_ROUTE` and `SIGN_UP_ROUTE` should theoretically never occur, but with Kustomer's sessions
        // being a bit unpredictable we have a few reported cases where this can occur, so we are adding it here as
        // well in an admittedly heavy-handed manner.
        return [SUPPORT_ACCESS_ROUTE, SIGN_IN_ROUTE, SIGN_UP_ROUTE, VERIFICATION_ROUTE];
    }

    function openKustomerConversationWithRetry(conversationId: string) {
        // when we get notified about having a new unread conversation, sometimes there is a race condition
        // and attempting to display that conversation gives an "invalid conversation id" error
        // so in that case, we retry after a short delay
        window.Kustomer.openConversationById({ conversationId }, (response, error) => {
            if (error) {
                setTimeout(() => {
                    window.Kustomer.openConversationById({ conversationId });
                }, 2000);
            }
        });
    }

    const loadContactUsButton = function () {
        const contactUsButton = document.getElementsByClassName("js-contact-us")[0];

        if (contactUsButton) {
            contactUsButton.removeAttribute("disabled");
            contactUsButton.classList.remove("disabled");
        }
    };

    const updateBadgeClasses = function (className, newValue) {
        const badge = document.getElementsByClassName("js-badge")[0];
        if (badge && badge.classList) {
            badge.classList.toggle(className, newValue);

            const button = badge.parentElement;
            const isBadgePresent = badge.classList.contains("unread") || badge.classList.contains("open");
            if (button && button.classList) {
                button.classList.toggle("badged", isBadgePresent);
            }
        }
    };

    const buildChatContainer = function () {
        const script = document.createElement("script");
        script.src = window.YNAB_CLIENT_CONSTANTS.KUSTOMER_CHAT_WEB_API_URL;
        script.setAttribute("data-kustomer-api-key", window.YNAB_CLIENT_CONSTANTS.KUSTOMER_CHAT_WEB_API_KEY);
        window.document.body.appendChild(script);
    };

    const getJwt = async function () {
        return await new Promise<{ token: string }>(function (res, rej) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            $.ajax({
                type: "GET",
                url: "/api/v2/user/help_token",
                headers: {
                    "X-Session-Token": YNAB.getSessionToken(),
                    "X-YNAB-Device-Id": YNAB.deviceId(),
                },
                success: function (response) {
                    res(response);
                },
                error: function (error) {
                    rej(error);
                },
            });
        });
    };

    const initializeKustomer = function () {
        if (window.Kustomer) {
            window.Kustomer.start(
                { brandId: window.YNAB_CLIENT_CONSTANTS.KUSTOMER_CHAT_WEB_APP_BRAND_ID, hideChatIcon: true },
                function () {
                    if (window.YNAB_CLIENT_CONSTANTS.USER) {
                        if (window.YNAB_CLIENT_CONSTANTS.HELP_BUTTON_ENABLED) {
                            buildChatButton();
                        }
                        loadContactUsButton();
                    } else {
                        // When no user is authenticated (ex: login page) we will logout just in case a previous user is still logged in.
                        window.Kustomer.logout();
                    }
                },
            );
        }
    };

    $(loadChat);
})(jQuery);
