this.YNAB = {
    /**
     * Returns the current session token stored on the meta tag.
     */
    getSessionToken() {
        return $("meta[name=session-token]").attr("content");
    },

    /**
     * Manually sets the current session token, updating the meta tag.
     * Does not override the current token if passed a null/undefined/empty one.
     */
    setSessionToken(newSessionToken) {
        if (newSessionToken) {
            $("meta[name=session-token]").attr("content", newSessionToken);
            YNABMobile.sessionTokenChanged(newSessionToken);
        }
    },

    /**
     * Helper to redirect the page to the given URL, so we have a single place
     * for change if needed.
     */
    redirect(url) {
        window.location.href = url;
    },

    /**
     * Helper to redirect the page to the given URL, giving it a little time
     * to finish processing any in-flight requests, like analytics API calls.
     */
    redirectWithDelay(url) {
        return setTimeout(() => YNAB.redirect(url), YNAB.testing ? 10 : 1000);
    },

    initCastleRequestHeaderInjection() {
        if (!!window.YNAB_CLIENT_CONSTANTS?.CASTLE_PUBLISHABLE_KEY && typeof window.Castle !== "undefined") {
            const setCastleRequestToken = function (xhr) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                window.Castle.createRequestToken().then((castleRequestToken) => {
                    xhr.setRequestHeader("X-Castle-Request-Token", castleRequestToken);
                });
            };
            // jQuery.ajax calls
            $(document).ajaxSend((_ev, xhr) => {
                setCastleRequestToken(xhr);
            });
            // rails-ujs xhr/remote calls
            $(document).on("ajax:beforeSend", (event) => {
                const xhr = event.detail[0];
                setCastleRequestToken(xhr);
            });
        }
    },

    /**
     * Keeps track of pages and callbacks to them so we can trigger initialization
     * code for the current page being accessed.
     *
     */
    _routes: {},

    /**
     * Configures a callback to be triggered on the given pages, allowing for more
     * fine grained initialization setup instead of using ready events everywhere.
     * Pages are composed of `controller#action` naming (based on Rails).
     *
     * It allows us to replace initialization code with return checks like this:
     *
     *     jQuery ($) ->
     *       registration = $ '.registration-container'
     *       return unless registration.length
     *       #... js stuff for registration goes here.
     *
     * With something like this:
     *
     *     YNAB.route 'registrations#edit', ->
     *       #... js stuff for registration goes here.
     */
    route(...args) {
        const callback = args.pop();
        const pages = args;

        for (const page of pages) {
            this._routes[page] = callback;
        }
    },

    /**
     * Triggers the callback(s) associated with the current page being accessed.
     * These callbacks are configured through `YNAB.route`, and triggered when the
     * page is ready, based on the current `controller#action`.
     *
     * The `$('body')` element contains a `data-page` attribute set by Rails with
     * the current `controller#action`, matching the client side, and it's passed
     * down to the callbacks which can make use of it to setup delegation events.
     */
    dispatch(body) {
        const page = body && body.data("page");
        const callback = page && this._routes[page];
        if (callback) {
            return callback(body);
        }
    },

    /**
     * Setup mobile-only callbacks on certain DOM elements to send back events for native to handle.
     */
    initMobile(mobileBody) {
        if (mobileBody.length == 0) {
            // Not on mobile context.
            return;
        }

        mobileBody.on("click", ".launch_app_button", () => {
            // We have existing Android versions handling the `.launch_app_button` click, so we only delegate
            // the new close event for clients that know about it, otherwise we simply let the event through.
            if (YNABMobile.closeWebView) {
                YNABMobile.closeWebView();
                return false;
            }
        });

        // Hide the button to change subscription plan if the mobile app does not support the hook. In other
        // words, if the app can't launch the subscription change plan flow, we don't want to show the option.
        // TODO: This condition can be removed after we have fully released the Android app with change
        // billing / subscription flow, and ran with it for a while.
        if (YNABMobile.openSubscriptionPlanChange) {
            mobileBody.on("click", "[data-mobile-subscription-plan-change]", () => {
                YNABMobile.openSubscriptionPlanChange();
                return false;
            });
        } else {
            mobileBody.find("[data-mobile-subscription-plan-change]").hide();
        }

        // If this version knows how to handle events, add hook to intercept them and send to mobile.
        // If they don't the code will fallback to triggering analytics events as the web.
        if (YNABMobile.trackEvent) {
            YNABAnalytics.initMobile();
        }
    },

    /**
     * Cross platform way to copy text to the clipboard used throughout the app
     */
    copyTextToClipboard(sourceElement) {
        // Copy sourceElement content to clipboard using an approach that also works on iOS >= 10
        // Source: https://stackoverflow.com/a/34046084/626911
        try {
            const oldContentEditable = sourceElement.contentEditable;
            const oldReadOnly = sourceElement.readOnly;
            const range = document.createRange();

            sourceElement.contenteditable = true;
            sourceElement.readonly = false;

            range.selectNodeContents(sourceElement);

            const s = window.getSelection();
            s.removeAllRanges();
            s.addRange(range);

            sourceElement.setSelectionRange(0, 500);

            sourceElement.contentEditable = oldContentEditable;
            sourceElement.readOnly = oldReadOnly;

            document.execCommand("copy");
            return true;
        } catch {
            console.log("Copy to clipboard not available. Please use Ctrl/Cmd+C to copy.");
            return false;
        }
    },

    logoutSelfService() {
        try {
            window.Kustomer?.close();
            window.Kustomer?.logout(() => window.Kustomer?.stop());
        } catch (e) {
            window.Rollbar.error(e);
        }
    },

    deviceId() {
        return $("meta[name=self-service-device-id]").attr("content");
    },
};
// Mobile specific hooks we expose for them to override as needed. They are implemented as no-op
// functions so we can keep the implementation documented and simpler by always calling them.
/* eslint-disable @typescript-eslint/no-empty-function */
this.YNABMobile = this.YNABMobile || {
    // Mobile can store the new session token so they're not logged out when it changes.
    sessionTokenChanged: (newSessionToken) => {},
    accountDeleted: () => {},
    // Delegate close / launch app clicks on mobile back to the apps to handle natively.
    closeWebView: () => {},
    // Android allows users to change the subscription plan, triggered via account settings.
    // If the app does not implement this hook, the option to trigger it is going to be hidden.
    openSubscriptionPlanChange: () => {},
    // Delegate analytics event tracking to the native apps.
    trackEvent: (name, properties) => {},
};

// if we have a window object, ensure it has these global props too
if (typeof window !== "undefined") {
    window.YNAB = window.YNAB || this.YNAB;
    window.YNABMobile = window.YNABMobile || this.YNABMobile;
}

// Configure the single ready event necessary to dispatch the initialization
// callbacks for the current page.
(function ($) {
    $(() => {
        YNAB.dispatch($("body"));
        YNAB.initMobile($('[data-ynab-device="ios"], [data-ynab-device="android"]'));
        YNAB.initCastleRequestHeaderInjection();
    });
})(jQuery);
