import { ready } from "./lib/ready";

ready(() => {
    const button = document.querySelector<HTMLButtonElement>(".js-support-access-confirmation-form button");
    if (button && button.dataset.enable === "true") {
        YNABAnalytics.logoutSelfService();
        YNABAnalytics.logoutAnalytics();
        button.removeAttribute("disabled");
    }
});
